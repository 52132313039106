.listItem {
  min-height: 56px;
}

.listItemWithTools {
  padding-right: 96px;
}

.listSubheader {
  margin-top: 15px;
  margin-bottom: 10px;
}

.itemToHideOrShow {
  visibility: hidden;
  opacity: 1;
  transition: opacity 0.2s linear;
}

@media (max-width: 600px) {
  .itemToHideOrShow {
    visibility: visible;
    opacity: 1;
  }
}

.itemToHover:hover .itemToHideOrShow {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s linear;
}
